<div (click)="openSupportForm()" *ngIf="!isSupportFormOpened" class="support-button-box">
    <img src="../../../../../assets/icons/contact.png" alt="Contact Us" />
    <p (click)="openSupportForm()" class="label" i18n>Contact</p>
</div>

<div *ngIf="isSupportFormOpened" class="support-form">
    <div class="header" i18n>Contact</div>
    <button (click)="closeSupportForm()" class="close-icon" color="primary" mat-icon-button>
        <mat-icon aria-label="close">clear</mat-icon>
    </button>
    <ng-scrollbar #scroll class="scrollbar">
        <div class="info-section">
            <img class="logo" src="../../../../../assets/logo.png" alt="FinMatch Logo">
            <p class="finmatch-team-label" i18n>FinMatch Team</p>
            <p class="experts-label" i18n>Financing Experts</p>
            <p class="phone-number">
                <mat-icon class="text-icon">phone</mat-icon>
                +49 (0) 711 / 995 215 0
            </p>
        </div>

        <div class="email-section">
            <mat-icon class="text-icon">email</mat-icon>&#x73;&#x75;&#x70;&#x70;&#x6F;&#x72;&#x74;&#x40;&#x66;&#x69;&#x6E;&#x6D;&#x61;&#x74;&#x63;&#x68;&#x2E;&#x64;&#x65;
        </div>

        <div class="form-section clear-after">
            <app-common-spinner [glass]="true" [visible]="showSpinner"></app-common-spinner>

            <div *ngIf="showSuccessMessage" class="success-box">
                <div class="success-header" i18n>Thank you</div>
                <div class="success-text" i18n>Your message has been sent.</div>
                <div class="success-text" i18n>The FinMatch Team will answer your message as soon as possible.</div>
                <button (click)="closeSupportForm()" class="button" color="primary" i18n mat-flat-button type="button">OK</button>
            </div>

            <p class="ask-finmatch-label" i18n>Ask FinMatch to</p>

            <mat-tab-group [dynamicHeight]="true" class="tab-group">
                <mat-tab>
                    <ng-template mat-tab-label>
                        <mat-icon class="text-icon">email</mat-icon>
                        <span class="tab-label" i18n>email you</span>
                    </ng-template>
                    <form (ngSubmit)="sendEmailSupportRequest()" [formGroup]="supportByEmailForm" class="form-group">
                        <mat-form-field class="form-field">
                            <input formControlName="name" i18n-placeholder matInput placeholder="Your name *">
                            <mat-error [errorMessage]="supportByEmailForm.controls.name"></mat-error>
                        </mat-form-field>

                        <mat-form-field class="form-field">
                            <input formControlName="email" i18n-placeholder matInput placeholder="Your email *">
                            <mat-error [errorMessage]="supportByEmailForm.controls.email"></mat-error>
                        </mat-form-field>

                        <mat-form-field class="form-field text-area">
                            <textarea #message [matAutosizeMaxRows]="15" [matAutosizeMinRows]="4" formControlName="message" i18n-placeholder mat-autosize matInput maxlength="500" placeholder="Message *"></textarea>
                            <mat-hint align="end">{{message.value.length}} / 500</mat-hint>
                            <mat-error [errorMessage]="supportByEmailForm.controls.message"></mat-error>
                        </mat-form-field>

                        <p class="approval-label" i18n="Support agreement">I agree to:</p>

                        <div class="form-field checkbox">
                            <mat-checkbox formControlName="privacyPolicy"></mat-checkbox>
                            <a class="checkbox-link" href="{{config.landing_URL + 'datenschutz'}}" i18n="Support agreement" target="_blank">Data Protection</a>
                            <mat-error *ngIf="supportByEmailForm.controls.privacyPolicy.invalid && supportByEmailForm.controls.privacyPolicy.touched" [errorMessage]="supportByEmailForm.controls.privacyPolicy" class="checkbox-error"></mat-error>
                        </div>

                        <div class="form-field checkbox">
                            <mat-checkbox formControlName="approvalTerms"></mat-checkbox>
                            <a class="checkbox-link" href="{{config.landing_URL + 'agb'}}" i18n="Support agreement" target="_blank">Terms and Conditions</a>
                            <mat-error *ngIf="supportByEmailForm.controls.approvalTerms.invalid && supportByEmailForm.controls.approvalTerms.touched" [errorMessage]="supportByEmailForm.controls.approvalTerms" class="checkbox-error"></mat-error>
                        </div>

                        <button class="button" color="primary" i18n mat-flat-button type="submit">SEND</button>
                    </form>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <mat-icon class="text-icon">phone</mat-icon>
                        <span class="tab-label" i18n>call you</span>
                    </ng-template>

                    <form (ngSubmit)="sendPhoneSupportRequest()" [formGroup]="supportByPhoneForm" class="form-group">
                        <mat-form-field class="form-field">
                            <input formControlName="name" i18n-placeholder matInput placeholder="Your name *">
                            <mat-error [errorMessage]="supportByPhoneForm.controls.name"></mat-error>
                        </mat-form-field>

                        <mat-form-field class="form-field">
                            <input formControlName="phoneNumber" i18n-placeholder matInput placeholder="Your phone number *">
                            <mat-error [errorMessage]="supportByPhoneForm.controls.phoneNumber"></mat-error>
                        </mat-form-field>

                        <mat-form-field class="form-field">
                            <input (blur)="supportByPhoneForm.controls.preferredDate.markAsUntouched()" (click)="datepicker.open()" [matDatepicker]="datepicker" [min]="today" [readonly]="true" formControlName="preferredDate" i18n-placeholder matInput placeholder="Preferred date *">
                            <mat-datepicker-toggle [for]="datepicker" matSuffix></mat-datepicker-toggle>
                            <mat-datepicker #datepicker></mat-datepicker>
                            <mat-error [errorMessage]="supportByPhoneForm.controls.preferredDate"></mat-error>
                        </mat-form-field>

                        <mat-form-field class="form-field">
                            <input formControlName="preferredTime" i18n-placeholder matInput placeholder="Preferred time *">
                            <mat-error [errorMessage]="supportByPhoneForm.controls.preferredTime"></mat-error>
                        </mat-form-field>

                        <p class="approval-label" i18n="Support agreement">I agree to:</p>

                        <div class="form-field checkbox">
                            <mat-checkbox formControlName="privacyPolicy"></mat-checkbox>
                            <a class="checkbox-link" href="{{config.landing_URL + 'datenschutz'}}" i18n="Support agreement" target="_blank">Data Protection</a>
                            <mat-error *ngIf="supportByPhoneForm.controls.privacyPolicy.invalid && supportByPhoneForm.controls.privacyPolicy.touched" [errorMessage]="supportByPhoneForm.controls.privacyPolicy" class="checkbox-error"></mat-error>
                        </div>

                        <div class="form-field checkbox">
                            <mat-checkbox formControlName="approvalTerms"></mat-checkbox>
                            <a class="checkbox-link" href="{{config.landing_URL + 'agb'}}" i18n="Support agreement" target="_blank">Terms and Conditions</a>
                            <mat-error *ngIf="supportByPhoneForm.controls.approvalTerms.invalid && supportByPhoneForm.controls.approvalTerms.touched" [errorMessage]="supportByPhoneForm.controls.approvalTerms" class="checkbox-error"></mat-error>
                        </div>

                        <button class="button" color="primary" i18n mat-flat-button type="submit">SEND</button>
                    </form>
                </mat-tab>
            </mat-tab-group>
        </div>
    </ng-scrollbar>
</div>
